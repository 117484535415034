import { gql } from "@apollo/client"

export const DEACTIVATE_PLATFORM_INTEGRATION = gql`
  mutation DeactivatePlatformIntegration($clientId: Int!, $platformIntegrationId: Int!) {
    deactivatePlatformIntegration(clientId: $clientId, platformIntegrationId: $platformIntegrationId) {
      __typename
      success
    }
  }
`
