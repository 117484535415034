import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useMutation } from "@apollo/client"
import { HStack, Text, useDisclosure } from "@chakra-ui/react"
import { cast } from "@fifty9a/lib-utils"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { RoutePaths } from "../../../AppRoutes"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { Platform, PlatformIntegration } from "../../../generated/graphql"
import { DEACTIVATE_PLATFORM_INTEGRATION, GET_PLATFORM_INTEGRATIONS } from "../../../graphql"
import usePermissions, { canDelete, canUpdate, canView } from "../../../hooks/usePermissions"
import useResourceUris from "../../../hooks/useResourceUris"
import useSettings from "../../../hooks/useSettings"
import { logError } from "../../../log"
import Button from "../../Button"
import ConfirmDialog, { type ConfirmDialogProps } from "../../ConfirmDialog"
import DSPLogoMapper from "../../DSPLogoMapper"
import PageTable from "../PageTable"

interface TableRowData {
  clientId: number
  name: string
  platformIntegrationId: number
  platform: Platform
  state: string
}

const toTableRow = (data: PlatformIntegration[]): TableRowData[] =>
  data.map((v, i) => ({
    key: i,
    ...v,
    name: v.name ?? "",
  }))

const columnHelper = createColumnHelper<TableRowData>()

interface PlatformIntegrationsTableProps {
  clientId: number
  data?: PlatformIntegration[]
  loading: boolean
}

type IntegrationToBeDeleted = {
  clientId: number
  platformIntegrationId: number
  name: string
}

const IntegrationsTable: FC<PlatformIntegrationsTableProps> = ({ clientId, data, loading }) => {
  const navigate = useNavigate()
  const { platformAdvertiserResourceUri, platformIntegrationResourceUri } = useResourceUris()
  const { permissions } = usePermissions()
  const [integrationToBeDeleted, setIntegrationToBeDeleted] = useState<IntegrationToBeDeleted | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.integrations)

  useEffect(() => {
    if (pageIndex !== paginationSettings.integrations.pageIndex || pageSize !== paginationSettings.integrations.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        integrations: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const [deactivatePlatformIntegration, { error: deactivateError, loading: deactivateLoading, reset: deactivateReset }] = useMutation(
    DEACTIVATE_PLATFORM_INTEGRATION,
    {
      refetchQueries: [GET_PLATFORM_INTEGRATIONS],
      awaitRefetchQueries: true,
    }
  )

  const onCancelDeleteIntegration = useCallback(() => {
    deactivateReset()
    onClose()
  }, [deactivateReset, onClose])

  const confirmationOpts = useMemo(() => {
    const onDeleteIntegration = async () => {
      try {
        await deactivatePlatformIntegration({
          variables: integrationToBeDeleted,
        })
        onClose()
      } catch (error) {
        logError(error)
      }
    }

    return cast<ConfirmDialogProps>({
      isOpen,
      errors: [deactivateError],
      confirmButtonLoading: deactivateLoading,
      cancelRef,
      onCancelOrClose: onCancelDeleteIntegration,
      onConfirm: onDeleteIntegration,
      renderText1: () => (
        <Text>
          You are about to delete integration <b>{integrationToBeDeleted?.name}</b>. This action is not reversible.
        </Text>
      ),
      renderText2: () => (
        <Text mt={4}>
          59A will immediately cease managing all managed Advertisers and Campaigns under this Integration. Please ensure that you are ready to take control of
          any managed campaigns.
        </Text>
      ),
      checkBoxText: "I understand. *",
      confirmButtonText: "Delete Integration",
    })
  }, [deactivatePlatformIntegration, onClose, integrationToBeDeleted, isOpen, deactivateError, deactivateLoading, cancelRef, onCancelDeleteIntegration])

  const tableColumns = useMemo(() => {
    const onStartDeleteIntegration = (opts: IntegrationToBeDeleted) => {
      setIntegrationToBeDeleted(opts)
      onOpen()
    }

    return [
      columnHelper.accessor("clientId", {
        id: "clientId",
        cell: (info) => info.getValue(),
        header: () => <span>Client ID</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "platform",
        cell: ({ row }) => (
          <div className="px-1">
            <DSPLogoMapper platform={row.original.platform} />
          </div>
        ),
        header: () => <span>Platform</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("platformIntegrationId", {
        id: "platformIntegrationId",
        cell: (info) => info.getValue(),
        header: () => <span>Integration Id</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("state", {
        id: "state",
        cell: (info) => info.getValue(),
        header: () => <span>State</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => (
          <HStack>
            {canView(permissions, platformAdvertiserResourceUri) && (
              <Button
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  navigate(RoutePaths.advertisersList.resolve(row.original!.clientId.toString(), row.original!.platformIntegrationId.toString()))
                }
                isInline={true}
              >
                View Advertisers
              </Button>
            )}
            {canUpdate(permissions, platformIntegrationResourceUri) && (
              <Button
                onClick={() => {
                  switch (row.original.platform) {
                    case Platform.Dv360:
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      navigate(RoutePaths.integrationSetupDV360.resolve(row.original!.clientId.toString()), {
                        state: { integrationId: row.original.platformIntegrationId },
                      })
                      break
                    case Platform.Facebook:
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      navigate(RoutePaths.integrationSetupFacebook.resolve(row.original!.clientId.toString()), {
                        state: { integrationId: row.original.platformIntegrationId },
                      })
                      break
                    case Platform.Xandr:
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      navigate(RoutePaths.integrationSetupXandr.resolve(row.original!.clientId.toString()), {
                        state: { integrationId: row.original.platformIntegrationId },
                      })
                      break
                  }
                }}
                isInline={true}
              >
                Edit
              </Button>
            )}
            {canDelete(permissions, platformIntegrationResourceUri) && (
              <Button
                isInline={true}
                onClick={() =>
                  onStartDeleteIntegration({
                    clientId: row.original.clientId,
                    platformIntegrationId: row.original.platformIntegrationId,
                    name: row.original.name,
                  })
                }
              >
                Delete
              </Button>
            )}
          </HStack>
        ),
      }),
    ] as ColumnDef<TableRowData, unknown>[]
  }, [navigate, onOpen, permissions, platformIntegrationResourceUri, platformAdvertiserResourceUri])

  const tableRows = useMemo(() => {
    if (data?.length) {
      return toTableRow(data)
    }
    return [] as TableRowData[]
  }, [data])

  return (
    <>
      <PageTable
        pagination={{ pageIndex, pageSize }}
        onPaginationChange={setPagination}
        loading={loading || deactivateLoading}
        defaultSort={[{ desc: false, id: "platformIntegrationId" }]}
        hasGlobalFilter={true}
        heightOtherElementsRem={21.05}
        tableColumns={tableColumns}
        tableRows={tableRows}
      />
      <ConfirmDialog {...confirmationOpts} />
    </>
  )
}

export default IntegrationsTable
