import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: any; output: any; }
  AWSDateTime: { input: any; output: any; }
  AWSTimestamp: { input: any; output: any; }
};

export type AgencyClient = {
  __typename?: 'AgencyClient';
  billingContact?: Maybe<Scalars['String']['output']>;
  clientContact?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CampaignAggregatedMetrics = {
  __typename?: 'CampaignAggregatedMetrics';
  dateFrom: Scalars['AWSDate']['output'];
  dateTo: Scalars['AWSDate']['output'];
  metrics: Array<Metric>;
  platformCampaignId: PlatformCampaignId;
};

export type CampaignAggregatedMetricsItemised = {
  __typename?: 'CampaignAggregatedMetricsItemised';
  itemId: Scalars['String']['output'];
  itemName?: Maybe<Scalars['String']['output']>;
  metrics: Array<Metric>;
};

export type CampaignDailyMetrics = {
  __typename?: 'CampaignDailyMetrics';
  dailyMetrics: Array<DailyMetric>;
  dateFrom: Scalars['AWSDate']['output'];
  dateTo: Scalars['AWSDate']['output'];
  platformCampaignId: PlatformCampaignId;
};

export type CampaignItemisedAggregatedMetrics = {
  __typename?: 'CampaignItemisedAggregatedMetrics';
  dateFrom?: Maybe<Scalars['String']['output']>;
  dateTo?: Maybe<Scalars['String']['output']>;
  itemisedMetrics: Array<CampaignAggregatedMetricsItemised>;
  platformCampaignId?: Maybe<PlatformCampaignId>;
};

export type CampaignKpi = {
  __typename?: 'CampaignKPI';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  minimise: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pixelIds?: Maybe<Array<Scalars['String']['output']>>;
  target: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  weight: Scalars['Int']['output'];
};

export enum CampaignType {
  Display = 'DISPLAY',
  Video = 'VIDEO',
  Youtube = 'YOUTUBE'
}

export type Client = AgencyClient | DirectClient | Fifty9AClient;

export enum ClientType {
  AgencyClient = 'AgencyClient',
  DirectClient = 'DirectClient',
  Fifty9AClient = 'Fifty9AClient'
}

export type ConversionPixel = {
  __typename?: 'ConversionPixel';
  name?: Maybe<Scalars['String']['output']>;
};

export type CurrencyMetric = ICurrency & IFloat & IMetric & {
  __typename?: 'CurrencyMetric';
  currency: Scalars['String']['output'];
  name: MetricName;
  pixels?: Maybe<Array<Pixel>>;
  valueFloat?: Maybe<Scalars['Float']['output']>;
};

export type DailyMetric = {
  __typename?: 'DailyMetric';
  date: Scalars['AWSDate']['output'];
  metrics: Array<Metric>;
};

export type DirectClient = {
  __typename?: 'DirectClient';
  billingContact?: Maybe<Scalars['String']['output']>;
  clientContact?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Dv360PlatformIntegrationParams = {
  __typename?: 'Dv360PlatformIntegrationParams';
  partnerId?: Maybe<Scalars['Int']['output']>;
};

export enum ErrorType {
  AdministratorRoleDoesNotExist = 'ADMINISTRATOR_ROLE_DOES_NOT_EXIST',
  ClientExists = 'CLIENT_EXISTS',
  MaxUserLimitExceeded = 'MAX_USER_LIMIT_EXCEEDED',
  PlatformCampaignDoesNotExist = 'PLATFORM_CAMPAIGN_DOES_NOT_EXIST',
  PlatformIntegrationDoesNotExist = 'PLATFORM_INTEGRATION_DOES_NOT_EXIST',
  RoleDoesNotExist = 'ROLE_DOES_NOT_EXIST',
  RoleExists = 'ROLE_EXISTS',
  RoleIsNotDeletable = 'ROLE_IS_NOT_DELETABLE',
  RoleIsNotEditable = 'ROLE_IS_NOT_EDITABLE',
  RoleUpdateNotAllowedError = 'ROLE_UPDATE_NOT_ALLOWED_ERROR',
  UserDoesNotExist = 'USER_DOES_NOT_EXIST',
  UserExists = 'USER_EXISTS',
  ValidationError = 'VALIDATION_ERROR'
}

export type FacebookPlatformIntegrationParams = {
  __typename?: 'FacebookPlatformIntegrationParams';
  adAccountId?: Maybe<Scalars['String']['output']>;
};

export type Fee = {
  __typename?: 'Fee';
  amount: Scalars['Float']['output'];
  feeType: FeeType;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type FeeInput = {
  amount: Scalars['Float']['input'];
  feeType: FeeType;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export enum FeeType {
  MarginCostPlus = 'MarginCostPlus'
}

export type Fifty9AClient = {
  __typename?: 'Fifty9AClient';
  billingContact?: Maybe<Scalars['String']['output']>;
  clientContact?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type FloatMetric = IMetric & {
  __typename?: 'FloatMetric';
  name: MetricName;
  pixels?: Maybe<Array<Pixel>>;
  valueFloat?: Maybe<Scalars['Float']['output']>;
};

export type IAssignees = {
  assignees?: Maybe<Array<UserProfile>>;
};

export type ICurrency = {
  currency: Scalars['String']['output'];
};

export type IFloat = {
  valueFloat?: Maybe<Scalars['Float']['output']>;
};

export type IInt = {
  valueInt?: Maybe<Scalars['Int']['output']>;
};

export type IMetric = {
  name: MetricName;
  pixels?: Maybe<Array<Pixel>>;
};

export type IPlatformCampaign = {
  campaignType?: Maybe<CampaignType>;
  clientId: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  fees?: Maybe<Array<Fee>>;
  id: Scalars['ID']['output'];
  kpis?: Maybe<Array<CampaignKpi>>;
  managed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platform: Platform;
  platformAdvertiserId: PlatformAdvertiserId;
  platformCampaignId: PlatformCampaignId;
  platformSetupStatus: PlatformSetupStatus;
};

export type IntMetric = IMetric & {
  __typename?: 'IntMetric';
  name: MetricName;
  pixels?: Maybe<Array<Pixel>>;
  valueInt?: Maybe<Scalars['Int']['output']>;
};

export type Kpi = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
  minimise: Scalars['Boolean']['input'];
  name: KpiName;
  pixelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  target: Scalars['Float']['input'];
  type: KpiType;
  weight: Scalars['Int']['input'];
};

export enum KpiName {
  Cpa = 'CPA',
  Cpc = 'CPC',
  Cpcv = 'CPCV',
  Cpm = 'CPM',
  Cpv = 'CPV',
  Ctr = 'CTR',
  Reach = 'REACH',
  Roas = 'ROAS',
  Vcpm = 'VCPM',
  Vcr = 'VCR',
  Viewability = 'VIEWABILITY'
}

export enum KpiType {
  Main = 'MAIN',
  Secondary = 'SECONDARY'
}

export type Metric = CurrencyMetric | FloatMetric | IntMetric;

export enum MetricName {
  Clicks = 'CLICKS',
  ClickReach = 'CLICK_REACH',
  CompleteViewsVideo = 'COMPLETE_VIEWS_VIDEO',
  Conversions = 'CONVERSIONS',
  CoViewedFrequency = 'CO_VIEWED_FREQUENCY',
  CoViewedImpressions = 'CO_VIEWED_IMPRESSIONS',
  CoViewedImpressionsReach = 'CO_VIEWED_IMPRESSIONS_REACH',
  Cpa = 'CPA',
  Cpc = 'CPC',
  Cpcv = 'CPCV',
  Cpm = 'CPM',
  Cpv = 'CPV',
  Ctr = 'CTR',
  Frequency = 'FREQUENCY',
  Impressions = 'IMPRESSIONS',
  ImpressionsReach = 'IMPRESSIONS_REACH',
  MeasurableImpressions = 'MEASURABLE_IMPRESSIONS',
  Roas = 'ROAS',
  Spend = 'SPEND',
  TotalReach = 'TOTAL_REACH',
  Vcpm = 'VCPM',
  Vcr = 'VCR',
  Viewability = 'VIEWABILITY',
  ViewableFrequency = 'VIEWABLE_FREQUENCY',
  ViewableImpressions = 'VIEWABLE_IMPRESSIONS',
  ViewableImpressionsReach = 'VIEWABLE_IMPRESSIONS_REACH'
}

export type Mutation = {
  __typename?: 'Mutation';
  assignUsersToPlatformCampaign?: Maybe<OperationResult>;
  createClient?: Maybe<Client>;
  createDv360PlatformIntegration?: Maybe<PlatformIntegration>;
  createFacebookPlatformIntegration?: Maybe<PlatformIntegration>;
  createUser?: Maybe<UserProfile>;
  createXandrPlatformIntegration?: Maybe<PlatformIntegration>;
  deactivatePlatformIntegration?: Maybe<OperationResult>;
  deleteRole?: Maybe<OperationResult>;
  deleteUser?: Maybe<OperationResult>;
  setPlatformCampaignManaged: PlatformCampaign;
  syncAdvertiser?: Maybe<OperationResult>;
  triggerSchedules?: Maybe<OperationResult>;
  updateAccountSettings?: Maybe<Client>;
  updateClient?: Maybe<Client>;
  updateDv360PlatformIntegration?: Maybe<PlatformIntegration>;
  updateFacebookPlatformIntegration?: Maybe<PlatformIntegration>;
  updatePlatformAdvertiser?: Maybe<PlatformAdvertiser>;
  updatePlatformCampaign: PlatformCampaign;
  updateRoleUsers?: Maybe<OperationResult>;
  updateUserRoles?: Maybe<OperationResult>;
  updateXandrPlatformIntegration?: Maybe<PlatformIntegration>;
  upsertRole?: Maybe<RoleWithPermissions>;
};


export type MutationAssignUsersToPlatformCampaignArgs = {
  clientId: Scalars['Int']['input'];
  platform: Platform;
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
  users?: InputMaybe<Array<RoleUserInput>>;
};


export type MutationCreateClientArgs = {
  billingContact: Scalars['String']['input'];
  clientContact: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: ClientType;
};


export type MutationCreateDv360PlatformIntegrationArgs = {
  clientId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  partnerId: Scalars['Int']['input'];
};


export type MutationCreateFacebookPlatformIntegrationArgs = {
  adAccountId: Scalars['String']['input'];
  clientId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  clientId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  isAdministrator?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
};


export type MutationCreateXandrPlatformIntegrationArgs = {
  clientId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationDeactivatePlatformIntegrationArgs = {
  clientId: Scalars['Int']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type MutationDeleteRoleArgs = {
  clientId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
};


export type MutationDeleteUserArgs = {
  clientId: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSetPlatformCampaignManagedArgs = {
  clientId: Scalars['Int']['input'];
  managed: Scalars['Boolean']['input'];
  platform: Platform;
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type MutationSyncAdvertiserArgs = {
  clientId: Scalars['Int']['input'];
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type MutationTriggerSchedulesArgs = {
  clientId: Scalars['Int']['input'];
  platform: Platform;
  platformDataType: PlatformDataType;
  platformIntegrationId: Scalars['Int']['input'];
};


export type MutationUpdateAccountSettingsArgs = {
  billingContact: Scalars['String']['input'];
  clientContact: Scalars['String']['input'];
  clientId: Scalars['Int']['input'];
};


export type MutationUpdateClientArgs = {
  billingContact: Scalars['String']['input'];
  clientContact: Scalars['String']['input'];
  clientId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateDv360PlatformIntegrationArgs = {
  clientId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  partnerId: Scalars['Int']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type MutationUpdateFacebookPlatformIntegrationArgs = {
  adAccountId: Scalars['String']['input'];
  clientId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  platformIntegrationId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};


export type MutationUpdatePlatformAdvertiserArgs = {
  advertiser: PlatformAdvertiserUpdateInput;
  clientId: Scalars['Int']['input'];
  platform: Platform;
};


export type MutationUpdatePlatformCampaignArgs = {
  clientId: Scalars['Int']['input'];
  platform: Platform;
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
  updateInput: PlatformCampaignUpdateInput;
};


export type MutationUpdateRoleUsersArgs = {
  clientId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
  users?: InputMaybe<Array<RoleUserInput>>;
};


export type MutationUpdateUserRolesArgs = {
  clientId: Scalars['Int']['input'];
  roles?: InputMaybe<Array<UserRoleInput>>;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateXandrPlatformIntegrationArgs = {
  clientId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  platformIntegrationId: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpsertRoleArgs = {
  clientId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  permissions: UpsertRolePermissionsInput;
  roleId?: InputMaybe<Scalars['Int']['input']>;
};

export type OperationResult = {
  __typename?: 'OperationResult';
  success: Scalars['Boolean']['output'];
};

export type PermissionActions = {
  __typename?: 'PermissionActions';
  actions?: Maybe<Array<ResourceActions>>;
  resourceUri: Scalars['String']['output'];
};

export type Pixel = {
  __typename?: 'Pixel';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum Platform {
  Dv360 = 'DV360',
  Facebook = 'FACEBOOK',
  Xandr = 'XANDR'
}

export type PlatformAdvertiser = {
  __typename?: 'PlatformAdvertiser';
  clientId: Scalars['Int']['output'];
  countCampaigns?: Maybe<Scalars['Int']['output']>;
  countManagedCampaigns?: Maybe<Scalars['Int']['output']>;
  lastSyncedAt: Scalars['Float']['output'];
  managed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  platformAdvertiserId: PlatformAdvertiserId;
};

export type PlatformAdvertiserId = {
  __typename?: 'PlatformAdvertiserId';
  id: Scalars['String']['output'];
  platform: Platform;
};

export type PlatformAdvertiserIdInput = {
  id: Scalars['String']['input'];
  platform: Platform;
};

export type PlatformAdvertiserUpdateInput = {
  managed: Scalars['Boolean']['input'];
  platformAdvertiserId: PlatformAdvertiserIdInput;
  platformIntegrationId: Scalars['Int']['input'];
};

export type PlatformCampaign = IPlatformCampaign & {
  __typename?: 'PlatformCampaign';
  assignees?: Maybe<Array<UserProfile>>;
  campaignType?: Maybe<CampaignType>;
  clientId: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  fees?: Maybe<Array<Fee>>;
  id: Scalars['ID']['output'];
  kpis?: Maybe<Array<CampaignKpi>>;
  managed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platform: Platform;
  platformAdvertiserId: PlatformAdvertiserId;
  platformCampaignId: PlatformCampaignId;
  platformSetupStatus: PlatformSetupStatus;
};

export type PlatformCampaignId = {
  __typename?: 'PlatformCampaignId';
  id: Scalars['String']['output'];
  platform: Platform;
};

export type PlatformCampaignIdInput = {
  id: Scalars['ID']['input'];
  platform: Platform;
};

export type PlatformCampaignUpdateInput = {
  campaignType?: InputMaybe<CampaignType>;
  fees?: InputMaybe<Array<FeeInput>>;
  kpis?: InputMaybe<Array<Kpi>>;
};

export type PlatformCampaignWithAssignees = IAssignees & IPlatformCampaign & {
  __typename?: 'PlatformCampaignWithAssignees';
  assignees?: Maybe<Array<UserProfile>>;
  campaignType?: Maybe<CampaignType>;
  clientId: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  fees?: Maybe<Array<Fee>>;
  id: Scalars['ID']['output'];
  kpis?: Maybe<Array<CampaignKpi>>;
  managed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platform: Platform;
  platformAdvertiserId: PlatformAdvertiserId;
  platformCampaignId: PlatformCampaignId;
  platformSetupStatus: PlatformSetupStatus;
};

export type PlatformConversionPixel = {
  __typename?: 'PlatformConversionPixel';
  clientId: Scalars['Int']['output'];
  conversionPixel: ConversionPixel;
  name: Scalars['String']['output'];
  platform: Platform;
  platformAdvertiserId?: Maybe<PlatformAdvertiserId>;
  platformConversionPixelId: PlatformConversionPixelId;
  platformIntegrationId: Scalars['Int']['output'];
};

export type PlatformConversionPixelId = {
  __typename?: 'PlatformConversionPixelId';
  id: Scalars['String']['output'];
  platform: Platform;
};

export type PlatformCreative = {
  __typename?: 'PlatformCreative';
  clientId: Scalars['Int']['output'];
  height: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  platformAdvertiserId: PlatformAdvertiserId;
  platformCampaignId: PlatformCampaignId;
  platformCreativeId: PlatformCreativeId;
  width: Scalars['Int']['output'];
};

export type PlatformCreativeId = {
  __typename?: 'PlatformCreativeId';
  id: Scalars['ID']['output'];
  platform?: Maybe<Platform>;
};

export enum PlatformDataType {
  Advertiser = 'ADVERTISER',
  AdAccount = 'AD_ACCOUNT',
  AdSet = 'AD_SET',
  BidMultipliers = 'BID_MULTIPLIERS',
  Campaign = 'CAMPAIGN',
  ConversionPixel = 'CONVERSION_PIXEL',
  CustomConversion = 'CUSTOM_CONVERSION',
  CustomModel = 'CUSTOM_MODEL',
  CustomModelById = 'CUSTOM_MODEL_BY_ID',
  CustomModelEvent = 'CUSTOM_MODEL_EVENT',
  CustomModelLeaf = 'CUSTOM_MODEL_LEAF',
  InsertionOrder = 'INSERTION_ORDER',
  LineItem = 'LINE_ITEM',
  LineItemModel = 'LINE_ITEM_MODEL',
  Performance = 'PERFORMANCE',
  PerformanceLineItemSpend = 'PERFORMANCE_LINE_ITEM_SPEND',
  PerformanceStandard = 'PERFORMANCE_STANDARD',
  PerformanceStandardCreative = 'PERFORMANCE_STANDARD_CREATIVE',
  PerformanceVideo = 'PERFORMANCE_VIDEO',
  PerformanceYoutube = 'PERFORMANCE_YOUTUBE',
  QrtzScoresProcessed = 'QRTZ_SCORES_PROCESSED',
  ReferenceBrowser = 'REFERENCE_BROWSER',
  ReferenceCity = 'REFERENCE_CITY',
  ReferenceCountry = 'REFERENCE_COUNTRY',
  ReferenceDeviceMake = 'REFERENCE_DEVICE_MAKE',
  ReferenceDeviceModel = 'REFERENCE_DEVICE_MODEL',
  ReferenceMobileApp = 'REFERENCE_MOBILE_APP',
  ReferenceMobileAppInstance = 'REFERENCE_MOBILE_APP_INSTANCE',
  ReferenceOperatingSystem = 'REFERENCE_OPERATING_SYSTEM',
  ReferenceOperatingSystemExtended = 'REFERENCE_OPERATING_SYSTEM_EXTENDED',
  ReferenceOperatingSystemFamily = 'REFERENCE_OPERATING_SYSTEM_FAMILY',
  ReferencePostCode = 'REFERENCE_POST_CODE',
  ReferenceRegion = 'REFERENCE_REGION',
  Standard = 'STANDARD',
  Video = 'VIDEO'
}

export type PlatformIntegration = {
  __typename?: 'PlatformIntegration';
  clientId: Scalars['Int']['output'];
  countAdvertisers?: Maybe<Scalars['Int']['output']>;
  countCampaigns?: Maybe<Scalars['Int']['output']>;
  countManagedAdvertisers?: Maybe<Scalars['Int']['output']>;
  countManagedCampaigns?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform: Platform;
  platformIntegrationId: Scalars['Int']['output'];
  platformParams?: Maybe<PlatformIntegrationParams>;
  state: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type PlatformIntegrationParams = {
  __typename?: 'PlatformIntegrationParams';
  dv360?: Maybe<Dv360PlatformIntegrationParams>;
  facebook?: Maybe<FacebookPlatformIntegrationParams>;
};

export type PlatformLineItem = {
  __typename?: 'PlatformLineItem';
  clientId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  platformAdvertiserId: PlatformAdvertiserId;
  platformCampaignId: PlatformCampaignId;
  platformLineItemId: PlatformLineItemId;
};

export type PlatformLineItemId = {
  __typename?: 'PlatformLineItemId';
  id: Scalars['String']['output'];
  platform: Platform;
};

export enum PlatformSetupStatus {
  NotSetup = 'NotSetup',
  ResetFailed = 'ResetFailed',
  ResetInProgress = 'ResetInProgress',
  SetupComplete = 'SetupComplete',
  SetupFailed = 'SetupFailed',
  SetupInProgress = 'SetupInProgress'
}

export type Query = {
  __typename?: 'Query';
  getCampaignAggregatedMetrics: CampaignAggregatedMetrics;
  getCampaignAggregatedMetricsByCreative: CampaignItemisedAggregatedMetrics;
  getCampaignAggregatedMetricsByLineItem: CampaignItemisedAggregatedMetrics;
  getCampaignCreativesAggregatedMetrics: CampaignAggregatedMetrics;
  getCampaignCreativesDailyMetrics: CampaignDailyMetrics;
  getCampaignCreativesWithDelivery: Array<PlatformCreative>;
  getCampaignDailyMetrics: CampaignDailyMetrics;
  getCampaignLast7DailyMetrics: CampaignDailyMetrics;
  getCampaignLineItemsAggregatedMetrics: CampaignAggregatedMetrics;
  getCampaignLineItemsDailyMetrics: CampaignDailyMetrics;
  getCampaignLineItemsWithDelivery: Array<PlatformLineItem>;
  getClient: Client;
  getClients?: Maybe<Array<Client>>;
  getCurrentUserProfile: UserProfileWithRoles;
  getPermissions: Array<PermissionActions>;
  getPlatformAdvertisersByIntegration?: Maybe<Array<PlatformAdvertiser>>;
  getPlatformCampaign: PlatformCampaign;
  getPlatformCampaigns?: Maybe<Array<PlatformCampaignWithAssignees>>;
  getPlatformConversionPixels: Array<PlatformConversionPixel>;
  getPlatformIntegration: PlatformIntegration;
  getPlatformIntegrations: Array<PlatformIntegration>;
  getRoleWithPermissions?: Maybe<RoleWithPermissions>;
  getRoles?: Maybe<Array<RoleWithUsers>>;
  getUserProfiles: Array<UserProfileWithRoles>;
};


export type QueryGetCampaignAggregatedMetricsArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignAggregatedMetricsByCreativeArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  creativeIds: Array<Scalars['ID']['input']>;
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignAggregatedMetricsByLineItemArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  lineItemIds: Array<Scalars['ID']['input']>;
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignCreativesAggregatedMetricsArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  creativeIds: Array<Scalars['ID']['input']>;
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignCreativesDailyMetricsArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  creativeIds: Array<Scalars['ID']['input']>;
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignCreativesWithDeliveryArgs = {
  clientId: Scalars['Int']['input'];
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignDailyMetricsArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignLast7DailyMetricsArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignLineItemsAggregatedMetricsArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  lineItemIds: Array<Scalars['ID']['input']>;
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignLineItemsDailyMetricsArgs = {
  campaignType: CampaignType;
  clientId: Scalars['Int']['input'];
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  lineItemIds: Array<Scalars['ID']['input']>;
  pixelIdsSets?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetCampaignLineItemsWithDeliveryArgs = {
  clientId: Scalars['Int']['input'];
  dateFrom: Scalars['AWSDate']['input'];
  dateTo: Scalars['AWSDate']['input'];
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetClientArgs = {
  clientId: Scalars['ID']['input'];
};


export type QueryGetPermissionsArgs = {
  resourceUris: Array<Scalars['String']['input']>;
};


export type QueryGetPlatformAdvertisersByIntegrationArgs = {
  clientId: Scalars['Int']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetPlatformCampaignArgs = {
  clientId: Scalars['Int']['input'];
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformCampaignId: Scalars['ID']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetPlatformCampaignsArgs = {
  clientId: Scalars['Int']['input'];
  platform: Platform;
  platformAdvertiserId: Scalars['String']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetPlatformConversionPixelsArgs = {
  clientId: Scalars['Int']['input'];
  platform: Platform;
  platformAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetPlatformIntegrationArgs = {
  clientId: Scalars['Int']['input'];
  platformIntegrationId: Scalars['Int']['input'];
};


export type QueryGetPlatformIntegrationsArgs = {
  clientId: Scalars['Int']['input'];
};


export type QueryGetRoleWithPermissionsArgs = {
  clientId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
};

export enum ResourceActions {
  Create = 'CREATE',
  Delete = 'DELETE',
  Manage = 'MANAGE',
  Reporting = 'REPORTING',
  Update = 'UPDATE',
  View = 'VIEW'
}

export type RolePermissions = {
  __typename?: 'RolePermissions';
  client: Array<ResourceActions>;
  platformAdvertiser: Array<ResourceActions>;
  platformCampaign: Array<ResourceActions>;
  platformIntegration: Array<ResourceActions>;
  user: Array<ResourceActions>;
};

export type RoleUser = {
  __typename?: 'RoleUser';
  email: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type RoleUserInput = {
  clientId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type RoleWithPermissions = {
  __typename?: 'RoleWithPermissions';
  clientId: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: RolePermissions;
  roleId: Scalars['Int']['output'];
};

export type RoleWithUsers = {
  __typename?: 'RoleWithUsers';
  clientId: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isDeletable: Scalars['Boolean']['output'];
  isEditable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  roleId: Scalars['Int']['output'];
  users?: Maybe<Array<RoleUser>>;
};

export type UpsertRolePermissionsInput = {
  client: Array<ResourceActions>;
  platformAdvertiser: Array<ResourceActions>;
  platformCampaign: Array<ResourceActions>;
  platformIntegration: Array<ResourceActions>;
  user: Array<ResourceActions>;
};

export type UserNames = {
  __typename?: 'UserNames';
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  clientId: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  name: UserNames;
  userId: Scalars['String']['output'];
};

export type UserProfileRole = {
  __typename?: 'UserProfileRole';
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  roleId: Scalars['Int']['output'];
};

export type UserProfileWithRoles = {
  __typename?: 'UserProfileWithRoles';
  clientId: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  name: UserNames;
  roles?: Maybe<Array<UserProfileRole>>;
  userId: Scalars['String']['output'];
};

export type UserRoleInput = {
  clientId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  Client: ( AgencyClient ) | ( DirectClient ) | ( Fifty9AClient );
  Metric: ( CurrencyMetric ) | ( FloatMetric ) | ( IntMetric );
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  IAssignees: ( PlatformCampaignWithAssignees );
  ICurrency: ( CurrencyMetric );
  IFloat: ( CurrencyMetric );
  IInt: never;
  IMetric: ( CurrencyMetric ) | ( FloatMetric ) | ( IntMetric );
  IPlatformCampaign: ( PlatformCampaign ) | ( PlatformCampaignWithAssignees );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSDate: ResolverTypeWrapper<Scalars['AWSDate']['output']>;
  AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']['output']>;
  AWSTimestamp: ResolverTypeWrapper<Scalars['AWSTimestamp']['output']>;
  AgencyClient: ResolverTypeWrapper<AgencyClient>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CampaignAggregatedMetrics: ResolverTypeWrapper<Omit<CampaignAggregatedMetrics, 'metrics'> & { metrics: Array<ResolversTypes['Metric']> }>;
  CampaignAggregatedMetricsItemised: ResolverTypeWrapper<Omit<CampaignAggregatedMetricsItemised, 'metrics'> & { metrics: Array<ResolversTypes['Metric']> }>;
  CampaignDailyMetrics: ResolverTypeWrapper<Omit<CampaignDailyMetrics, 'dailyMetrics'> & { dailyMetrics: Array<ResolversTypes['DailyMetric']> }>;
  CampaignItemisedAggregatedMetrics: ResolverTypeWrapper<Omit<CampaignItemisedAggregatedMetrics, 'itemisedMetrics'> & { itemisedMetrics: Array<ResolversTypes['CampaignAggregatedMetricsItemised']> }>;
  CampaignKPI: ResolverTypeWrapper<CampaignKpi>;
  CampaignType: CampaignType;
  Client: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Client']>;
  ClientType: ClientType;
  ConversionPixel: ResolverTypeWrapper<ConversionPixel>;
  CurrencyMetric: ResolverTypeWrapper<CurrencyMetric>;
  DailyMetric: ResolverTypeWrapper<Omit<DailyMetric, 'metrics'> & { metrics: Array<ResolversTypes['Metric']> }>;
  DirectClient: ResolverTypeWrapper<DirectClient>;
  Dv360PlatformIntegrationParams: ResolverTypeWrapper<Dv360PlatformIntegrationParams>;
  ErrorType: ErrorType;
  FacebookPlatformIntegrationParams: ResolverTypeWrapper<FacebookPlatformIntegrationParams>;
  Fee: ResolverTypeWrapper<Fee>;
  FeeInput: FeeInput;
  FeeType: FeeType;
  Fifty9AClient: ResolverTypeWrapper<Fifty9AClient>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FloatMetric: ResolverTypeWrapper<FloatMetric>;
  IAssignees: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['IAssignees']>;
  ICurrency: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['ICurrency']>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IFloat: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['IFloat']>;
  IInt: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['IInt']>;
  IMetric: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['IMetric']>;
  IPlatformCampaign: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['IPlatformCampaign']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntMetric: ResolverTypeWrapper<IntMetric>;
  KPI: Kpi;
  KPIName: KpiName;
  KPIType: KpiType;
  Metric: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Metric']>;
  MetricName: MetricName;
  Mutation: ResolverTypeWrapper<{}>;
  OperationResult: ResolverTypeWrapper<OperationResult>;
  PermissionActions: ResolverTypeWrapper<PermissionActions>;
  Pixel: ResolverTypeWrapper<Pixel>;
  Platform: Platform;
  PlatformAdvertiser: ResolverTypeWrapper<PlatformAdvertiser>;
  PlatformAdvertiserId: ResolverTypeWrapper<PlatformAdvertiserId>;
  PlatformAdvertiserIdInput: PlatformAdvertiserIdInput;
  PlatformAdvertiserUpdateInput: PlatformAdvertiserUpdateInput;
  PlatformCampaign: ResolverTypeWrapper<PlatformCampaign>;
  PlatformCampaignId: ResolverTypeWrapper<PlatformCampaignId>;
  PlatformCampaignIdInput: PlatformCampaignIdInput;
  PlatformCampaignUpdateInput: PlatformCampaignUpdateInput;
  PlatformCampaignWithAssignees: ResolverTypeWrapper<PlatformCampaignWithAssignees>;
  PlatformConversionPixel: ResolverTypeWrapper<PlatformConversionPixel>;
  PlatformConversionPixelId: ResolverTypeWrapper<PlatformConversionPixelId>;
  PlatformCreative: ResolverTypeWrapper<PlatformCreative>;
  PlatformCreativeId: ResolverTypeWrapper<PlatformCreativeId>;
  PlatformDataType: PlatformDataType;
  PlatformIntegration: ResolverTypeWrapper<PlatformIntegration>;
  PlatformIntegrationParams: ResolverTypeWrapper<PlatformIntegrationParams>;
  PlatformLineItem: ResolverTypeWrapper<PlatformLineItem>;
  PlatformLineItemId: ResolverTypeWrapper<PlatformLineItemId>;
  PlatformSetupStatus: PlatformSetupStatus;
  Query: ResolverTypeWrapper<{}>;
  ResourceActions: ResourceActions;
  RolePermissions: ResolverTypeWrapper<RolePermissions>;
  RoleUser: ResolverTypeWrapper<RoleUser>;
  RoleUserInput: RoleUserInput;
  RoleWithPermissions: ResolverTypeWrapper<RoleWithPermissions>;
  RoleWithUsers: ResolverTypeWrapper<RoleWithUsers>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  UpsertRolePermissionsInput: UpsertRolePermissionsInput;
  UserNames: ResolverTypeWrapper<UserNames>;
  UserProfile: ResolverTypeWrapper<UserProfile>;
  UserProfileRole: ResolverTypeWrapper<UserProfileRole>;
  UserProfileWithRoles: ResolverTypeWrapper<UserProfileWithRoles>;
  UserRoleInput: UserRoleInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSDate: Scalars['AWSDate']['output'];
  AWSDateTime: Scalars['AWSDateTime']['output'];
  AWSTimestamp: Scalars['AWSTimestamp']['output'];
  AgencyClient: AgencyClient;
  Boolean: Scalars['Boolean']['output'];
  CampaignAggregatedMetrics: Omit<CampaignAggregatedMetrics, 'metrics'> & { metrics: Array<ResolversParentTypes['Metric']> };
  CampaignAggregatedMetricsItemised: Omit<CampaignAggregatedMetricsItemised, 'metrics'> & { metrics: Array<ResolversParentTypes['Metric']> };
  CampaignDailyMetrics: Omit<CampaignDailyMetrics, 'dailyMetrics'> & { dailyMetrics: Array<ResolversParentTypes['DailyMetric']> };
  CampaignItemisedAggregatedMetrics: Omit<CampaignItemisedAggregatedMetrics, 'itemisedMetrics'> & { itemisedMetrics: Array<ResolversParentTypes['CampaignAggregatedMetricsItemised']> };
  CampaignKPI: CampaignKpi;
  Client: ResolversUnionTypes<ResolversParentTypes>['Client'];
  ConversionPixel: ConversionPixel;
  CurrencyMetric: CurrencyMetric;
  DailyMetric: Omit<DailyMetric, 'metrics'> & { metrics: Array<ResolversParentTypes['Metric']> };
  DirectClient: DirectClient;
  Dv360PlatformIntegrationParams: Dv360PlatformIntegrationParams;
  FacebookPlatformIntegrationParams: FacebookPlatformIntegrationParams;
  Fee: Fee;
  FeeInput: FeeInput;
  Fifty9AClient: Fifty9AClient;
  Float: Scalars['Float']['output'];
  FloatMetric: FloatMetric;
  IAssignees: ResolversInterfaceTypes<ResolversParentTypes>['IAssignees'];
  ICurrency: ResolversInterfaceTypes<ResolversParentTypes>['ICurrency'];
  ID: Scalars['ID']['output'];
  IFloat: ResolversInterfaceTypes<ResolversParentTypes>['IFloat'];
  IInt: ResolversInterfaceTypes<ResolversParentTypes>['IInt'];
  IMetric: ResolversInterfaceTypes<ResolversParentTypes>['IMetric'];
  IPlatformCampaign: ResolversInterfaceTypes<ResolversParentTypes>['IPlatformCampaign'];
  Int: Scalars['Int']['output'];
  IntMetric: IntMetric;
  KPI: Kpi;
  Metric: ResolversUnionTypes<ResolversParentTypes>['Metric'];
  Mutation: {};
  OperationResult: OperationResult;
  PermissionActions: PermissionActions;
  Pixel: Pixel;
  PlatformAdvertiser: PlatformAdvertiser;
  PlatformAdvertiserId: PlatformAdvertiserId;
  PlatformAdvertiserIdInput: PlatformAdvertiserIdInput;
  PlatformAdvertiserUpdateInput: PlatformAdvertiserUpdateInput;
  PlatformCampaign: PlatformCampaign;
  PlatformCampaignId: PlatformCampaignId;
  PlatformCampaignIdInput: PlatformCampaignIdInput;
  PlatformCampaignUpdateInput: PlatformCampaignUpdateInput;
  PlatformCampaignWithAssignees: PlatformCampaignWithAssignees;
  PlatformConversionPixel: PlatformConversionPixel;
  PlatformConversionPixelId: PlatformConversionPixelId;
  PlatformCreative: PlatformCreative;
  PlatformCreativeId: PlatformCreativeId;
  PlatformIntegration: PlatformIntegration;
  PlatformIntegrationParams: PlatformIntegrationParams;
  PlatformLineItem: PlatformLineItem;
  PlatformLineItemId: PlatformLineItemId;
  Query: {};
  RolePermissions: RolePermissions;
  RoleUser: RoleUser;
  RoleUserInput: RoleUserInput;
  RoleWithPermissions: RoleWithPermissions;
  RoleWithUsers: RoleWithUsers;
  String: Scalars['String']['output'];
  UpsertRolePermissionsInput: UpsertRolePermissionsInput;
  UserNames: UserNames;
  UserProfile: UserProfile;
  UserProfileRole: UserProfileRole;
  UserProfileWithRoles: UserProfileWithRoles;
  UserRoleInput: UserRoleInput;
};

export interface AwsDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDate'], any> {
  name: 'AWSDate';
}

export interface AwsDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
  name: 'AWSDateTime';
}

export interface AwsTimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTimestamp'], any> {
  name: 'AWSTimestamp';
}

export type AgencyClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['AgencyClient'] = ResolversParentTypes['AgencyClient']> = {
  billingContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignAggregatedMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignAggregatedMetrics'] = ResolversParentTypes['CampaignAggregatedMetrics']> = {
  dateFrom?: Resolver<ResolversTypes['AWSDate'], ParentType, ContextType>;
  dateTo?: Resolver<ResolversTypes['AWSDate'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['Metric']>, ParentType, ContextType>;
  platformCampaignId?: Resolver<ResolversTypes['PlatformCampaignId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignAggregatedMetricsItemisedResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignAggregatedMetricsItemised'] = ResolversParentTypes['CampaignAggregatedMetricsItemised']> = {
  itemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['Metric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignDailyMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignDailyMetrics'] = ResolversParentTypes['CampaignDailyMetrics']> = {
  dailyMetrics?: Resolver<Array<ResolversTypes['DailyMetric']>, ParentType, ContextType>;
  dateFrom?: Resolver<ResolversTypes['AWSDate'], ParentType, ContextType>;
  dateTo?: Resolver<ResolversTypes['AWSDate'], ParentType, ContextType>;
  platformCampaignId?: Resolver<ResolversTypes['PlatformCampaignId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignItemisedAggregatedMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignItemisedAggregatedMetrics'] = ResolversParentTypes['CampaignItemisedAggregatedMetrics']> = {
  dateFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  itemisedMetrics?: Resolver<Array<ResolversTypes['CampaignAggregatedMetricsItemised']>, ParentType, ContextType>;
  platformCampaignId?: Resolver<Maybe<ResolversTypes['PlatformCampaignId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignKpiResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignKPI'] = ResolversParentTypes['CampaignKPI']> = {
  max?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  min?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minimise?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pixelIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  target?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client'] = ResolversParentTypes['Client']> = {
  __resolveType: TypeResolveFn<'AgencyClient' | 'DirectClient' | 'Fifty9AClient', ParentType, ContextType>;
};

export type ConversionPixelResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversionPixel'] = ResolversParentTypes['ConversionPixel']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyMetric'] = ResolversParentTypes['CurrencyMetric']> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['MetricName'], ParentType, ContextType>;
  pixels?: Resolver<Maybe<Array<ResolversTypes['Pixel']>>, ParentType, ContextType>;
  valueFloat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailyMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyMetric'] = ResolversParentTypes['DailyMetric']> = {
  date?: Resolver<ResolversTypes['AWSDate'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['Metric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DirectClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['DirectClient'] = ResolversParentTypes['DirectClient']> = {
  billingContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Dv360PlatformIntegrationParamsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dv360PlatformIntegrationParams'] = ResolversParentTypes['Dv360PlatformIntegrationParams']> = {
  partnerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacebookPlatformIntegrationParamsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacebookPlatformIntegrationParams'] = ResolversParentTypes['FacebookPlatformIntegrationParams']> = {
  adAccountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fee'] = ResolversParentTypes['Fee']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  feeType?: Resolver<ResolversTypes['FeeType'], ParentType, ContextType>;
  max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Fifty9AClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fifty9AClient'] = ResolversParentTypes['Fifty9AClient']> = {
  billingContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FloatMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['FloatMetric'] = ResolversParentTypes['FloatMetric']> = {
  name?: Resolver<ResolversTypes['MetricName'], ParentType, ContextType>;
  pixels?: Resolver<Maybe<Array<ResolversTypes['Pixel']>>, ParentType, ContextType>;
  valueFloat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAssigneesResolvers<ContextType = any, ParentType extends ResolversParentTypes['IAssignees'] = ResolversParentTypes['IAssignees']> = {
  __resolveType: TypeResolveFn<'PlatformCampaignWithAssignees', ParentType, ContextType>;
  assignees?: Resolver<Maybe<Array<ResolversTypes['UserProfile']>>, ParentType, ContextType>;
};

export type ICurrencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ICurrency'] = ResolversParentTypes['ICurrency']> = {
  __resolveType: TypeResolveFn<'CurrencyMetric', ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type IFloatResolvers<ContextType = any, ParentType extends ResolversParentTypes['IFloat'] = ResolversParentTypes['IFloat']> = {
  __resolveType: TypeResolveFn<'CurrencyMetric', ParentType, ContextType>;
  valueFloat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
};

export type IIntResolvers<ContextType = any, ParentType extends ResolversParentTypes['IInt'] = ResolversParentTypes['IInt']> = {
  __resolveType: TypeResolveFn<null, ParentType, ContextType>;
  valueInt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
};

export type IMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['IMetric'] = ResolversParentTypes['IMetric']> = {
  __resolveType: TypeResolveFn<'CurrencyMetric' | 'FloatMetric' | 'IntMetric', ParentType, ContextType>;
  name?: Resolver<ResolversTypes['MetricName'], ParentType, ContextType>;
  pixels?: Resolver<Maybe<Array<ResolversTypes['Pixel']>>, ParentType, ContextType>;
};

export type IPlatformCampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['IPlatformCampaign'] = ResolversParentTypes['IPlatformCampaign']> = {
  __resolveType: TypeResolveFn<'PlatformCampaign' | 'PlatformCampaignWithAssignees', ParentType, ContextType>;
  campaignType?: Resolver<Maybe<ResolversTypes['CampaignType']>, ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fees?: Resolver<Maybe<Array<ResolversTypes['Fee']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kpis?: Resolver<Maybe<Array<ResolversTypes['CampaignKPI']>>, ParentType, ContextType>;
  managed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  platformAdvertiserId?: Resolver<ResolversTypes['PlatformAdvertiserId'], ParentType, ContextType>;
  platformCampaignId?: Resolver<ResolversTypes['PlatformCampaignId'], ParentType, ContextType>;
  platformSetupStatus?: Resolver<ResolversTypes['PlatformSetupStatus'], ParentType, ContextType>;
};

export type IntMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntMetric'] = ResolversParentTypes['IntMetric']> = {
  name?: Resolver<ResolversTypes['MetricName'], ParentType, ContextType>;
  pixels?: Resolver<Maybe<Array<ResolversTypes['Pixel']>>, ParentType, ContextType>;
  valueInt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metric'] = ResolversParentTypes['Metric']> = {
  __resolveType: TypeResolveFn<'CurrencyMetric' | 'FloatMetric' | 'IntMetric', ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  assignUsersToPlatformCampaign?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationAssignUsersToPlatformCampaignArgs, 'clientId' | 'platform' | 'platformCampaignId' | 'platformIntegrationId'>>;
  createClient?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationCreateClientArgs, 'billingContact' | 'clientContact' | 'name' | 'type'>>;
  createDv360PlatformIntegration?: Resolver<Maybe<ResolversTypes['PlatformIntegration']>, ParentType, ContextType, RequireFields<MutationCreateDv360PlatformIntegrationArgs, 'clientId' | 'name' | 'partnerId'>>;
  createFacebookPlatformIntegration?: Resolver<Maybe<ResolversTypes['PlatformIntegration']>, ParentType, ContextType, RequireFields<MutationCreateFacebookPlatformIntegrationArgs, 'adAccountId' | 'clientId' | 'name' | 'token'>>;
  createUser?: Resolver<Maybe<ResolversTypes['UserProfile']>, ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'clientId' | 'email' | 'firstName' | 'lastName'>>;
  createXandrPlatformIntegration?: Resolver<Maybe<ResolversTypes['PlatformIntegration']>, ParentType, ContextType, RequireFields<MutationCreateXandrPlatformIntegrationArgs, 'clientId' | 'name' | 'password' | 'username'>>;
  deactivatePlatformIntegration?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationDeactivatePlatformIntegrationArgs, 'clientId' | 'platformIntegrationId'>>;
  deleteRole?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationDeleteRoleArgs, 'clientId' | 'roleId'>>;
  deleteUser?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'clientId' | 'userId'>>;
  setPlatformCampaignManaged?: Resolver<ResolversTypes['PlatformCampaign'], ParentType, ContextType, RequireFields<MutationSetPlatformCampaignManagedArgs, 'clientId' | 'managed' | 'platform' | 'platformCampaignId' | 'platformIntegrationId'>>;
  syncAdvertiser?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationSyncAdvertiserArgs, 'clientId' | 'platform' | 'platformAdvertiserId' | 'platformIntegrationId'>>;
  triggerSchedules?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationTriggerSchedulesArgs, 'clientId' | 'platform' | 'platformDataType' | 'platformIntegrationId'>>;
  updateAccountSettings?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationUpdateAccountSettingsArgs, 'billingContact' | 'clientContact' | 'clientId'>>;
  updateClient?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationUpdateClientArgs, 'billingContact' | 'clientContact' | 'clientId' | 'name'>>;
  updateDv360PlatformIntegration?: Resolver<Maybe<ResolversTypes['PlatformIntegration']>, ParentType, ContextType, RequireFields<MutationUpdateDv360PlatformIntegrationArgs, 'clientId' | 'name' | 'partnerId' | 'platformIntegrationId'>>;
  updateFacebookPlatformIntegration?: Resolver<Maybe<ResolversTypes['PlatformIntegration']>, ParentType, ContextType, RequireFields<MutationUpdateFacebookPlatformIntegrationArgs, 'adAccountId' | 'clientId' | 'name' | 'platformIntegrationId' | 'token'>>;
  updatePlatformAdvertiser?: Resolver<Maybe<ResolversTypes['PlatformAdvertiser']>, ParentType, ContextType, RequireFields<MutationUpdatePlatformAdvertiserArgs, 'advertiser' | 'clientId' | 'platform'>>;
  updatePlatformCampaign?: Resolver<ResolversTypes['PlatformCampaign'], ParentType, ContextType, RequireFields<MutationUpdatePlatformCampaignArgs, 'clientId' | 'platform' | 'platformCampaignId' | 'platformIntegrationId' | 'updateInput'>>;
  updateRoleUsers?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationUpdateRoleUsersArgs, 'clientId' | 'roleId'>>;
  updateUserRoles?: Resolver<Maybe<ResolversTypes['OperationResult']>, ParentType, ContextType, RequireFields<MutationUpdateUserRolesArgs, 'clientId' | 'userId'>>;
  updateXandrPlatformIntegration?: Resolver<Maybe<ResolversTypes['PlatformIntegration']>, ParentType, ContextType, RequireFields<MutationUpdateXandrPlatformIntegrationArgs, 'clientId' | 'name' | 'password' | 'platformIntegrationId' | 'username'>>;
  upsertRole?: Resolver<Maybe<ResolversTypes['RoleWithPermissions']>, ParentType, ContextType, RequireFields<MutationUpsertRoleArgs, 'clientId' | 'description' | 'name' | 'permissions'>>;
};

export type OperationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationResult'] = ResolversParentTypes['OperationResult']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionActionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionActions'] = ResolversParentTypes['PermissionActions']> = {
  actions?: Resolver<Maybe<Array<ResolversTypes['ResourceActions']>>, ParentType, ContextType>;
  resourceUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PixelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pixel'] = ResolversParentTypes['Pixel']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformAdvertiserResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformAdvertiser'] = ResolversParentTypes['PlatformAdvertiser']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countCampaigns?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countManagedCampaigns?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastSyncedAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  managed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platformAdvertiserId?: Resolver<ResolversTypes['PlatformAdvertiserId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformAdvertiserIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformAdvertiserId'] = ResolversParentTypes['PlatformAdvertiserId']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformCampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformCampaign'] = ResolversParentTypes['PlatformCampaign']> = {
  assignees?: Resolver<Maybe<Array<ResolversTypes['UserProfile']>>, ParentType, ContextType>;
  campaignType?: Resolver<Maybe<ResolversTypes['CampaignType']>, ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fees?: Resolver<Maybe<Array<ResolversTypes['Fee']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kpis?: Resolver<Maybe<Array<ResolversTypes['CampaignKPI']>>, ParentType, ContextType>;
  managed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  platformAdvertiserId?: Resolver<ResolversTypes['PlatformAdvertiserId'], ParentType, ContextType>;
  platformCampaignId?: Resolver<ResolversTypes['PlatformCampaignId'], ParentType, ContextType>;
  platformSetupStatus?: Resolver<ResolversTypes['PlatformSetupStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformCampaignIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformCampaignId'] = ResolversParentTypes['PlatformCampaignId']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformCampaignWithAssigneesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformCampaignWithAssignees'] = ResolversParentTypes['PlatformCampaignWithAssignees']> = {
  assignees?: Resolver<Maybe<Array<ResolversTypes['UserProfile']>>, ParentType, ContextType>;
  campaignType?: Resolver<Maybe<ResolversTypes['CampaignType']>, ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fees?: Resolver<Maybe<Array<ResolversTypes['Fee']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kpis?: Resolver<Maybe<Array<ResolversTypes['CampaignKPI']>>, ParentType, ContextType>;
  managed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  platformAdvertiserId?: Resolver<ResolversTypes['PlatformAdvertiserId'], ParentType, ContextType>;
  platformCampaignId?: Resolver<ResolversTypes['PlatformCampaignId'], ParentType, ContextType>;
  platformSetupStatus?: Resolver<ResolversTypes['PlatformSetupStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformConversionPixelResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformConversionPixel'] = ResolversParentTypes['PlatformConversionPixel']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversionPixel?: Resolver<ResolversTypes['ConversionPixel'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  platformAdvertiserId?: Resolver<Maybe<ResolversTypes['PlatformAdvertiserId']>, ParentType, ContextType>;
  platformConversionPixelId?: Resolver<ResolversTypes['PlatformConversionPixelId'], ParentType, ContextType>;
  platformIntegrationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformConversionPixelIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformConversionPixelId'] = ResolversParentTypes['PlatformConversionPixelId']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformCreativeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformCreative'] = ResolversParentTypes['PlatformCreative']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  height?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platformAdvertiserId?: Resolver<ResolversTypes['PlatformAdvertiserId'], ParentType, ContextType>;
  platformCampaignId?: Resolver<ResolversTypes['PlatformCampaignId'], ParentType, ContextType>;
  platformCreativeId?: Resolver<ResolversTypes['PlatformCreativeId'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformCreativeIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformCreativeId'] = ResolversParentTypes['PlatformCreativeId']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['Platform']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformIntegration'] = ResolversParentTypes['PlatformIntegration']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countAdvertisers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countCampaigns?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countManagedAdvertisers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countManagedCampaigns?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  platformIntegrationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  platformParams?: Resolver<Maybe<ResolversTypes['PlatformIntegrationParams']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformIntegrationParamsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformIntegrationParams'] = ResolversParentTypes['PlatformIntegrationParams']> = {
  dv360?: Resolver<Maybe<ResolversTypes['Dv360PlatformIntegrationParams']>, ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['FacebookPlatformIntegrationParams']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformLineItem'] = ResolversParentTypes['PlatformLineItem']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platformAdvertiserId?: Resolver<ResolversTypes['PlatformAdvertiserId'], ParentType, ContextType>;
  platformCampaignId?: Resolver<ResolversTypes['PlatformCampaignId'], ParentType, ContextType>;
  platformLineItemId?: Resolver<ResolversTypes['PlatformLineItemId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformLineItemIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformLineItemId'] = ResolversParentTypes['PlatformLineItemId']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['Platform'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getCampaignAggregatedMetrics?: Resolver<ResolversTypes['CampaignAggregatedMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignAggregatedMetricsArgs, 'campaignType' | 'clientId' | 'dateFrom' | 'dateTo' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignAggregatedMetricsByCreative?: Resolver<ResolversTypes['CampaignItemisedAggregatedMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignAggregatedMetricsByCreativeArgs, 'campaignType' | 'clientId' | 'creativeIds' | 'dateFrom' | 'dateTo' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignAggregatedMetricsByLineItem?: Resolver<ResolversTypes['CampaignItemisedAggregatedMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignAggregatedMetricsByLineItemArgs, 'campaignType' | 'clientId' | 'dateFrom' | 'dateTo' | 'lineItemIds' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignCreativesAggregatedMetrics?: Resolver<ResolversTypes['CampaignAggregatedMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignCreativesAggregatedMetricsArgs, 'campaignType' | 'clientId' | 'creativeIds' | 'dateFrom' | 'dateTo' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignCreativesDailyMetrics?: Resolver<ResolversTypes['CampaignDailyMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignCreativesDailyMetricsArgs, 'campaignType' | 'clientId' | 'creativeIds' | 'dateFrom' | 'dateTo' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignCreativesWithDelivery?: Resolver<Array<ResolversTypes['PlatformCreative']>, ParentType, ContextType, RequireFields<QueryGetCampaignCreativesWithDeliveryArgs, 'clientId' | 'dateFrom' | 'dateTo' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignDailyMetrics?: Resolver<ResolversTypes['CampaignDailyMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignDailyMetricsArgs, 'campaignType' | 'clientId' | 'dateFrom' | 'dateTo' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignLast7DailyMetrics?: Resolver<ResolversTypes['CampaignDailyMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignLast7DailyMetricsArgs, 'campaignType' | 'clientId' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignLineItemsAggregatedMetrics?: Resolver<ResolversTypes['CampaignAggregatedMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignLineItemsAggregatedMetricsArgs, 'campaignType' | 'clientId' | 'dateFrom' | 'dateTo' | 'lineItemIds' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignLineItemsDailyMetrics?: Resolver<ResolversTypes['CampaignDailyMetrics'], ParentType, ContextType, RequireFields<QueryGetCampaignLineItemsDailyMetricsArgs, 'campaignType' | 'clientId' | 'dateFrom' | 'dateTo' | 'lineItemIds' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getCampaignLineItemsWithDelivery?: Resolver<Array<ResolversTypes['PlatformLineItem']>, ParentType, ContextType, RequireFields<QueryGetCampaignLineItemsWithDeliveryArgs, 'clientId' | 'dateFrom' | 'dateTo' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getClient?: Resolver<ResolversTypes['Client'], ParentType, ContextType, RequireFields<QueryGetClientArgs, 'clientId'>>;
  getClients?: Resolver<Maybe<Array<ResolversTypes['Client']>>, ParentType, ContextType>;
  getCurrentUserProfile?: Resolver<ResolversTypes['UserProfileWithRoles'], ParentType, ContextType>;
  getPermissions?: Resolver<Array<ResolversTypes['PermissionActions']>, ParentType, ContextType, RequireFields<QueryGetPermissionsArgs, 'resourceUris'>>;
  getPlatformAdvertisersByIntegration?: Resolver<Maybe<Array<ResolversTypes['PlatformAdvertiser']>>, ParentType, ContextType, RequireFields<QueryGetPlatformAdvertisersByIntegrationArgs, 'clientId' | 'platformIntegrationId'>>;
  getPlatformCampaign?: Resolver<ResolversTypes['PlatformCampaign'], ParentType, ContextType, RequireFields<QueryGetPlatformCampaignArgs, 'clientId' | 'platform' | 'platformAdvertiserId' | 'platformCampaignId' | 'platformIntegrationId'>>;
  getPlatformCampaigns?: Resolver<Maybe<Array<ResolversTypes['PlatformCampaignWithAssignees']>>, ParentType, ContextType, RequireFields<QueryGetPlatformCampaignsArgs, 'clientId' | 'platform' | 'platformAdvertiserId' | 'platformIntegrationId'>>;
  getPlatformConversionPixels?: Resolver<Array<ResolversTypes['PlatformConversionPixel']>, ParentType, ContextType, RequireFields<QueryGetPlatformConversionPixelsArgs, 'clientId' | 'platform' | 'platformIntegrationId'>>;
  getPlatformIntegration?: Resolver<ResolversTypes['PlatformIntegration'], ParentType, ContextType, RequireFields<QueryGetPlatformIntegrationArgs, 'clientId' | 'platformIntegrationId'>>;
  getPlatformIntegrations?: Resolver<Array<ResolversTypes['PlatformIntegration']>, ParentType, ContextType, RequireFields<QueryGetPlatformIntegrationsArgs, 'clientId'>>;
  getRoleWithPermissions?: Resolver<Maybe<ResolversTypes['RoleWithPermissions']>, ParentType, ContextType, RequireFields<QueryGetRoleWithPermissionsArgs, 'clientId' | 'roleId'>>;
  getRoles?: Resolver<Maybe<Array<ResolversTypes['RoleWithUsers']>>, ParentType, ContextType>;
  getUserProfiles?: Resolver<Array<ResolversTypes['UserProfileWithRoles']>, ParentType, ContextType>;
};

export type RolePermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RolePermissions'] = ResolversParentTypes['RolePermissions']> = {
  client?: Resolver<Array<ResolversTypes['ResourceActions']>, ParentType, ContextType>;
  platformAdvertiser?: Resolver<Array<ResolversTypes['ResourceActions']>, ParentType, ContextType>;
  platformCampaign?: Resolver<Array<ResolversTypes['ResourceActions']>, ParentType, ContextType>;
  platformIntegration?: Resolver<Array<ResolversTypes['ResourceActions']>, ParentType, ContextType>;
  user?: Resolver<Array<ResolversTypes['ResourceActions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoleUser'] = ResolversParentTypes['RoleUser']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleWithPermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoleWithPermissions'] = ResolversParentTypes['RoleWithPermissions']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<ResolversTypes['RolePermissions'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleWithUsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoleWithUsers'] = ResolversParentTypes['RoleWithUsers']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDeletable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  users?: Resolver<Maybe<Array<ResolversTypes['RoleUser']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNamesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserNames'] = ResolversParentTypes['UserNames']> = {
  first?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['UserNames'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfileRole'] = ResolversParentTypes['UserProfileRole']> = {
  isAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileWithRolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfileWithRoles'] = ResolversParentTypes['UserProfileWithRoles']> = {
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['UserNames'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['UserProfileRole']>>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSDate?: GraphQLScalarType;
  AWSDateTime?: GraphQLScalarType;
  AWSTimestamp?: GraphQLScalarType;
  AgencyClient?: AgencyClientResolvers<ContextType>;
  CampaignAggregatedMetrics?: CampaignAggregatedMetricsResolvers<ContextType>;
  CampaignAggregatedMetricsItemised?: CampaignAggregatedMetricsItemisedResolvers<ContextType>;
  CampaignDailyMetrics?: CampaignDailyMetricsResolvers<ContextType>;
  CampaignItemisedAggregatedMetrics?: CampaignItemisedAggregatedMetricsResolvers<ContextType>;
  CampaignKPI?: CampaignKpiResolvers<ContextType>;
  Client?: ClientResolvers<ContextType>;
  ConversionPixel?: ConversionPixelResolvers<ContextType>;
  CurrencyMetric?: CurrencyMetricResolvers<ContextType>;
  DailyMetric?: DailyMetricResolvers<ContextType>;
  DirectClient?: DirectClientResolvers<ContextType>;
  Dv360PlatformIntegrationParams?: Dv360PlatformIntegrationParamsResolvers<ContextType>;
  FacebookPlatformIntegrationParams?: FacebookPlatformIntegrationParamsResolvers<ContextType>;
  Fee?: FeeResolvers<ContextType>;
  Fifty9AClient?: Fifty9AClientResolvers<ContextType>;
  FloatMetric?: FloatMetricResolvers<ContextType>;
  IAssignees?: IAssigneesResolvers<ContextType>;
  ICurrency?: ICurrencyResolvers<ContextType>;
  IFloat?: IFloatResolvers<ContextType>;
  IInt?: IIntResolvers<ContextType>;
  IMetric?: IMetricResolvers<ContextType>;
  IPlatformCampaign?: IPlatformCampaignResolvers<ContextType>;
  IntMetric?: IntMetricResolvers<ContextType>;
  Metric?: MetricResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OperationResult?: OperationResultResolvers<ContextType>;
  PermissionActions?: PermissionActionsResolvers<ContextType>;
  Pixel?: PixelResolvers<ContextType>;
  PlatformAdvertiser?: PlatformAdvertiserResolvers<ContextType>;
  PlatformAdvertiserId?: PlatformAdvertiserIdResolvers<ContextType>;
  PlatformCampaign?: PlatformCampaignResolvers<ContextType>;
  PlatformCampaignId?: PlatformCampaignIdResolvers<ContextType>;
  PlatformCampaignWithAssignees?: PlatformCampaignWithAssigneesResolvers<ContextType>;
  PlatformConversionPixel?: PlatformConversionPixelResolvers<ContextType>;
  PlatformConversionPixelId?: PlatformConversionPixelIdResolvers<ContextType>;
  PlatformCreative?: PlatformCreativeResolvers<ContextType>;
  PlatformCreativeId?: PlatformCreativeIdResolvers<ContextType>;
  PlatformIntegration?: PlatformIntegrationResolvers<ContextType>;
  PlatformIntegrationParams?: PlatformIntegrationParamsResolvers<ContextType>;
  PlatformLineItem?: PlatformLineItemResolvers<ContextType>;
  PlatformLineItemId?: PlatformLineItemIdResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RolePermissions?: RolePermissionsResolvers<ContextType>;
  RoleUser?: RoleUserResolvers<ContextType>;
  RoleWithPermissions?: RoleWithPermissionsResolvers<ContextType>;
  RoleWithUsers?: RoleWithUsersResolvers<ContextType>;
  UserNames?: UserNamesResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
  UserProfileRole?: UserProfileRoleResolvers<ContextType>;
  UserProfileWithRoles?: UserProfileWithRolesResolvers<ContextType>;
};

