import React, { FC, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { Flex, useToast } from "@chakra-ui/react"
import { ErrorBoundary } from "react-error-boundary"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import Button from "../../components/Button"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import AdvertisersTable from "../../components/tables/AdvertisersTable"
import { PlatformAdvertiser, PlatformIntegration } from "../../generated/graphql"
import { GET_PLATFORM_ADVERTISERS_BY_INTEGRATION, GET_PLATFORM_INTEGRATION, TRIGGER_SCHEDULES } from "../../graphql"
import { useAdvertiserParams } from "../../hooks/navigationHooks"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canView } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import { logError, logUnhandledError } from "../../log"
import AuthenticatedProps from "../AuthenticatedProps"

type PlatformAdvertisersPageProps = AuthenticatedProps

const AdvertisersPage: FC<PlatformAdvertisersPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformAdvertiserResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()

  const navigate = useNavigate()
  const { clientId: paramsClientId, integrationId } = useAdvertiserParams()

  const breadcrumbItems: BreadcrumbItemProps[] = [
    { text: "Integrations", onClick: () => navigate(RoutePaths.integrationsList.resolve(paramsClientId)) },
    {
      text: integrationId,
      onClick: () => navigate(RoutePaths.advertisersList.resolve(paramsClientId, integrationId)),
    },
    { text: "Advertisers" },
  ]
  if (user?.is59A) {
    breadcrumbItems.splice(0, 0, {
      text: "Clients",
      onClick: () => navigate(RoutePaths.clientsList.resolve()),
    })
    breadcrumbItems.splice(1, 0, {
      text: paramsClientId,
      onClick: () => navigate(RoutePaths.integrationsList.resolve(paramsClientId)),
    })
  }

  const clientId = paramsClientId ?? user?.clientId

  const [advertiserData, setAdvertiserData] = useState<Array<PlatformAdvertiser>>()
  const [integrationData, setIntegrationData] = useState<PlatformIntegration>()

  const {
    loading: loadingGetAdvertisers,
    error: errorLoadingGetAdvertisers,
    data: getAdvertisersData,
  } = useQuery(GET_PLATFORM_ADVERTISERS_BY_INTEGRATION, {
    variables: {
      clientId: Number(clientId),
      platformIntegrationId: Number(integrationId),
    },
    fetchPolicy: "no-cache",
  })

  const { error: errorLoadingGetIntegration, data: getIntegrationData } = useQuery(GET_PLATFORM_INTEGRATION, {
    variables: {
      clientId: Number(clientId),
      platformIntegrationId: Number(integrationId),
    },
  })

  const toast = useToast()
  const [triggerSchedules, { loading: triggerSchedulesLoading, error: triggerSchedulesError }] = useMutation(TRIGGER_SCHEDULES)

  enum AdvertiserDataTypeMapping {
    XANDR = "ADVERTISER",
    DV360 = "ADVERTISER",
    FACEBOOK = "AD_ACCOUNT",
  }

  const syncWithDsp = async () => {
    await triggerSchedules({
      variables: {
        clientId,
        platform: integrationData?.platform,
        platformIntegrationId: Number(integrationId),
        platformDataType: AdvertiserDataTypeMapping[integrationData?.platform as keyof typeof AdvertiserDataTypeMapping],
      },
    })

    toast({
      title: "Synchronising with DSP...",
      description: "Please refresh the page in a few minutes.",
      status: "success",
      duration: 9000,
      isClosable: true,
    })
  }

  useEffect(() => {
    if (triggerSchedulesError) {
      toast({
        title: "DSP synchronisation failed.",
        description: "Please try again later or contact support.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })

      logError("ERROR", triggerSchedulesError)
    }
  }, [toast, triggerSchedulesError])

  useEffect(() => {
    if (errorLoadingGetAdvertisers) {
      logError("ERROR", errorLoadingGetAdvertisers)
    }
    if (getAdvertisersData) {
      setAdvertiserData(getAdvertisersData.getPlatformAdvertisersByIntegration as Array<PlatformAdvertiser>)
    }
  }, [errorLoadingGetAdvertisers, getAdvertisersData])

  useEffect(() => {
    if (errorLoadingGetIntegration) {
      logError("ERROR", errorLoadingGetIntegration)
    }
    if (getIntegrationData) {
      setIntegrationData(getIntegrationData.getPlatformIntegration)
    }
  }, [errorLoadingGetIntegration, getIntegrationData])

  const pageTitle = `Advertisers`

  const fallbackErrorMessage = `There is a problem with ${pageTitle}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${pageTitle}`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in ${pageTitle} when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && canView(permissions, platformAdvertiserResourceUri) && (
        <>
          <Flex justify="start" py={3} mb="-3.25rem">
            <Button isLoading={triggerSchedulesLoading} onClick={syncWithDsp} isDisabled={integrationData?.state !== "Active"}>
              Synchronise with DSP
            </Button>
          </Flex>
          <ErrorBoundary
            fallback={
              <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
            }
            onError={logUnhandledError}
          >
            <AdvertisersTable data={advertiserData} loading={loadingGetAdvertisers} />
          </ErrorBoundary>
        </>
      )}
      {!permissionsLoading && !canView(permissions, platformAdvertiserResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default AdvertisersPage
