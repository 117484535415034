import React, { FC, MutableRefObject, ReactElement, useCallback, useState } from "react"
import { ApolloError } from "@apollo/client"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Checkbox,
  HStack,
  VStack,
} from "@chakra-ui/react"
import Button from "../Button"
import ErrorText from "../forms/ErrorText"

export type ConfirmDialogProps = {
  isOpen: boolean
  cancelRef: MutableRefObject<null>
  onCancelOrClose: VoidFunction
  title?: string
  renderText1: () => ReactElement
  renderText2?: () => ReactElement
  errors?: Array<ApolloError | undefined>
  errorMessage?: string
  checkBoxText?: string
  confirmButtonText: string
  onConfirm: () => Promise<void>
  confirmButtonLoading: boolean
}

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const { isOpen, cancelRef, onCancelOrClose, confirmButtonLoading, onConfirm, errors, errorMessage } = props
  const { title = "Confirmation Required", renderText1, renderText2, checkBoxText, confirmButtonText } = props

  const [checkboxIsChecked, setCheckboxIsChecked] = useState(false)

  const handleCancelOrClose = useCallback(() => {
    onCancelOrClose()
    setCheckboxIsChecked(false)
  }, [onCancelOrClose, setCheckboxIsChecked])

  const handleConfirm = useCallback(async () => {
    await onConfirm()
    setCheckboxIsChecked(false)
  }, [onConfirm, setCheckboxIsChecked])

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleCancelOrClose} size="2xl">
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>
            {renderText1()}
            {renderText2 && renderText2()}
            {checkBoxText && (
              <Checkbox mt={4} isChecked={checkboxIsChecked} onChange={() => setCheckboxIsChecked(!checkboxIsChecked)}>
                {checkBoxText}
              </Checkbox>
            )}
            <VStack h={8} align="flex-start">
              {errorMessage && <ErrorText size="small" message={errorMessage} />}
              {errors &&
                errors.length > 0 &&
                errors.map((error) => error?.graphQLErrors.map((graphqlError) => <ErrorText size="small" message={graphqlError.message} />))}
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack>
              <Button isLoading={confirmButtonLoading} isDisabled={Boolean(checkBoxText) && !checkboxIsChecked} colorScheme="red" onClick={handleConfirm}>
                {confirmButtonText}
              </Button>
              <Button buttonRef={cancelRef} isSecondary={true} onClick={handleCancelOrClose}>
                Cancel
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ConfirmDialog
