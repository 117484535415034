import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useMutation } from "@apollo/client"
import { HStack, Switch, Text, useDisclosure } from "@chakra-ui/react"
import { cast } from "@fifty9a/lib-utils"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useNavigate, useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { RoutePaths } from "../../../AppRoutes"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { Platform, PlatformAdvertiser } from "../../../generated/graphql"
import { GET_PLATFORM_ADVERTISERS_BY_INTEGRATION, UPDATE_PLATFORM_ADVERTISER } from "../../../graphql"
import usePermissions, { canUpdate, canView } from "../../../hooks/usePermissions"
import useResourceUris from "../../../hooks/useResourceUris"
import useSettings from "../../../hooks/useSettings"
import Button from "../../Button"
import ConfirmationDialog, { type ConfirmDialogProps } from "../../ConfirmDialog"
import DSPLogoMapper from "../../DSPLogoMapper"
import PageTable from "../PageTable"

interface TableRowData {
  clientId: number
  platformAdvertiserId: string
  platform: Platform
  name: string
  managed: boolean
}

const toTableRow = (data: PlatformAdvertiser[]): TableRowData[] =>
  data.map((v, i) => ({
    key: i,
    ...v,
    platformAdvertiserId: v.platformAdvertiserId.id,
    platform: v.platformAdvertiserId.platform,
  }))

const columnHelper = createColumnHelper<TableRowData>()

interface PlatformAdvertisersTableProps {
  data?: PlatformAdvertiser[]
  loading: boolean
}

type AdvertiserToBeUnmanaged = {
  clientId: number
  platform: Platform
  platformIntegrationId: number
  platformAdvertiserId: string
  name: string
}

const AdvertisersTable: FC<PlatformAdvertisersTableProps> = ({ data, loading }) => {
  const { clientId, integrationId } = useParams()

  const navigate = useNavigate()
  const { platformAdvertiserResourceUri, platformCampaignResourceUri } = useResourceUris()

  const { permissions } = usePermissions()

  const [advertiserToBeUnmanaged, setAdvertiserToBeUnmanaged] = useState<AdvertiserToBeUnmanaged | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.advertisers)

  useEffect(() => {
    if (pageIndex !== paginationSettings.advertisers.pageIndex || pageSize !== paginationSettings.advertisers.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        advertisers: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const [updatePlatformAdvertiser, { error: updateError, loading: updateLoading, reset: updateReset }] = useMutation(UPDATE_PLATFORM_ADVERTISER, {
    refetchQueries: [GET_PLATFORM_ADVERTISERS_BY_INTEGRATION],
    awaitRefetchQueries: true,
  })

  const onCancelUnmanageAdvertiser = useCallback(() => {
    updateReset()
    onClose()
  }, [updateReset, onClose])

  const confirmationOpts = useMemo(() => {
    const onUnmanageAdvertiser = async () => {
      await updatePlatformAdvertiser({
        variables: {
          clientId: Number(clientId),
          platform: advertiserToBeUnmanaged?.platform,
          advertiser: {
            platformAdvertiserId: {
              platform: advertiserToBeUnmanaged?.platform,
              id: advertiserToBeUnmanaged?.platformAdvertiserId,
            },
            managed: false,
            platformIntegrationId: Number(integrationId),
          },
        },
      })
      onClose()
    }

    return cast<ConfirmDialogProps>({
      isOpen,
      cancelRef,
      errors: [updateError],
      renderText1: () => (
        <Text>
          You are about to unmanage advertiser <b>{advertiserToBeUnmanaged?.name}</b>. This action is not reversible. You are about to unmanage{" "}
          <b>{advertiserToBeUnmanaged?.name}</b>.
        </Text>
      ),
      renderText2: () => (
        <Text mt={4}>
          59A will immediately cease managing all managed Campaigns under this Advertiser. Please ensure that you are ready to take control of these Campaigns.
        </Text>
      ),
      confirmButtonText: "Unmanage Advertiser",
      confirmButtonLoading: updateLoading,
      checkBoxText: "I understand. *",
      onConfirm: onUnmanageAdvertiser,
      onCancelOrClose: onCancelUnmanageAdvertiser,
    })
  }, [advertiserToBeUnmanaged, clientId, integrationId, isOpen, onCancelUnmanageAdvertiser, onClose, updateError, updateLoading, updatePlatformAdvertiser])

  const tableColumns = useMemo(() => {
    const onStartUnmanageAdvertiser = (opts: AdvertiserToBeUnmanaged) => {
      setAdvertiserToBeUnmanaged(opts)
      onOpen()
    }

    return [
      columnHelper.accessor("managed", {
        id: "managedForSort",
        cell: (info) => info.getValue(),
        header: () => <span>Managed Sort</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("managed", {
        id: "managed",
        header: () => <span>Managed</span>,
        cell: (info) => (
          <HStack>
            <Switch
              isDisabled={updateLoading || !canUpdate(permissions, platformAdvertiserResourceUri)}
              isChecked={info.getValue()}
              onChange={async () => {
                if (info.row.original.managed) {
                  onStartUnmanageAdvertiser({
                    clientId: Number(clientId),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    platform: info.row.original!.platform,
                    platformIntegrationId: Number(integrationId),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    platformAdvertiserId: info.row.original!.platformAdvertiserId,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    name: info.row.original!.name,
                  })
                } else {
                  await updatePlatformAdvertiser({
                    variables: {
                      clientId: Number(clientId),
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      platform: info.row.original!.platform,
                      advertiser: {
                        platformAdvertiserId: {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          platform: info.row.original!.platform,
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          id: info.row.original!.platformAdvertiserId,
                        },
                        managed: true,
                        platformIntegrationId: Number(integrationId),
                      },
                    },
                  })
                }
              }}
            />
          </HStack>
        ),
      }),
      columnHelper.accessor("platformAdvertiserId", {
        id: "platformAdvertiserId",
        cell: (info) => info.getValue(),
        header: () => <span>Advertiser Id</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "platform",
        cell: ({ row }) => (
          <div className="px-1">
            <DSPLogoMapper platform={row.original.platform} />
          </div>
        ),
        header: () => <span>Platform</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => (
          <div className="px-1">
            {canView(permissions, platformCampaignResourceUri) && (
              <Button
                isDisabled={!row.original?.managed}
                onClick={() => {
                  if (clientId && integrationId) {
                    navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, row.original?.platform ?? "", row.original?.platformAdvertiserId ?? ""))
                  }
                }}
                isInline={true}
              >
                View Campaigns
              </Button>
            )}
          </div>
        ),
      }),
    ] as ColumnDef<TableRowData, unknown>[]
  }, [
    onOpen,
    permissions,
    platformAdvertiserResourceUri,
    updateLoading,
    clientId,
    integrationId,
    updatePlatformAdvertiser,
    platformCampaignResourceUri,
    navigate,
  ])

  const tableRows = useMemo(() => {
    if (data?.length) {
      return toTableRow(data)
    }
    return [] as TableRowData[]
  }, [data])

  return (
    <>
      {integrationId && (
        <>
          <PageTable
            loading={loading}
            defaultSort={[
              { desc: true, id: "managedForSort" },
              { desc: false, id: "name" },
            ]}
            columnVisibility={{ managedForSort: false }}
            pagination={{ pageIndex, pageSize }}
            onPaginationChange={setPagination}
            hasGlobalFilter={true}
            heightOtherElementsRem={20.35}
            tableColumns={tableColumns}
            tableRows={tableRows}
          />
          {confirmationOpts && <ConfirmationDialog {...confirmationOpts} />}
        </>
      )}
    </>
  )
}

export default AdvertisersTable
