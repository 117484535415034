import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { ApolloError, useMutation } from "@apollo/client"
import { InfoIcon } from "@chakra-ui/icons"
import { Alert, AlertDialog, AlertIcon, Box, Flex, HStack, Spinner, Switch, Text, Tooltip, useDisclosure, VStack } from "@chakra-ui/react"
import { cast } from "@fifty9a/lib-utils"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { RoutePaths } from "../../../AppRoutes"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { CampaignKpi, CampaignType, Platform, PlatformCampaignWithAssignees, PlatformSetupStatus, UserProfile } from "../../../generated/graphql"
import { GET_PLATFORM_CAMPAIGNS, SET_PLATFORM_CAMPAIGN_MANAGED } from "../../../graphql"
import { useCampaignsParams } from "../../../hooks/navigationHooks"
import usePermissions, { canUpdate, canViewReports } from "../../../hooks/usePermissions"
import useResourceUris from "../../../hooks/useResourceUris"
import useSettings from "../../../hooks/useSettings"
import colors from "../../../theme/colors"
import { Optional } from "../../../types"
import Avatar from "../../Avatar"
import Button from "../../Button"
import ConfirmDialog, { type ConfirmDialogProps } from "../../ConfirmDialog"
import DSPLogoMapper from "../../DSPLogoMapper"
import PageTable from "../PageTable"

export interface CampaignsTableRowData {
  clientId: number
  advertiserId: string
  campaignId: string
  platform: Platform
  name: string
  platformSetupStatus: PlatformSetupStatus
  currency: string
  campaignType?: CampaignType
  managed: boolean
  kpis?: CampaignKpi[]
  assignees?: UserProfile[]
}

const toTableRow = (data: PlatformCampaignWithAssignees[]): CampaignsTableRowData[] =>
  data.map((v, i) => ({
    key: i,
    ...v,
    advertiserId: v.platformAdvertiserId.id,
    campaignId: v.platformCampaignId.id,
    campaignType: v.campaignType ?? undefined,
    kpis: v.kpis ?? undefined,
    assignees: v.assignees ?? undefined,
  }))

const columnHelper = createColumnHelper<CampaignsTableRowData>()

interface PlatformCampaignsTableProps {
  data?: Optional<PlatformCampaignWithAssignees[]>
  error?: ApolloError
  loading: boolean
  allUsers: UserProfile[]
  onAssignUsers: (campaign: CampaignsTableRowData, allUsers: UserProfile[]) => void
}

const CampaignsTable: FC<PlatformCampaignsTableProps> = ({ data: tableData, error: tableError, loading: tableLoading, allUsers, onAssignUsers }) => {
  const { clientId, integrationId } = useCampaignsParams()
  const navigate = useNavigate()
  const { platformCampaignResourceUri } = useResourceUris()
  const { permissions } = usePermissions()

  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.campaigns)

  useEffect(() => {
    if (pageIndex !== paginationSettings.campaigns.pageIndex || pageSize !== paginationSettings.campaigns.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        campaigns: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const [setPlatformCampaignManaged, { loading: setManagedLoading, error: setManagedError }] = useMutation(SET_PLATFORM_CAMPAIGN_MANAGED, {
    refetchQueries: [GET_PLATFORM_CAMPAIGNS],
    awaitRefetchQueries: true,
  })

  const [selectedCampaign, setSelectedCampaign] = useState<PlatformCampaignWithAssignees | undefined>(undefined)

  // confirm un-manage campaign dialog
  const cancelConfirmUnmanageRef = useRef(null)
  const { isOpen: isUnmanageConfirmationOpen, onOpen: onUnmanageConfirmationOpen, onClose: onUnmanageConfirmationClose } = useDisclosure()

  const confirmationOpts = useMemo(() => {
    const confirmUnmanageCampaign = async () => {
      onUnmanageConfirmationClose()
      if (selectedCampaign) {
        await setPlatformCampaignManaged({
          variables: {
            clientId: Number(clientId),
            platform: selectedCampaign.platform,
            platformIntegrationId: Number(integrationId),
            platformCampaignId: selectedCampaign.platformCampaignId.id,
            managed: false,
          },
        })
        setSelectedCampaign(undefined)
      }
    }

    return cast<ConfirmDialogProps>({
      isOpen: isUnmanageConfirmationOpen,
      errors: [setManagedError],
      renderText1: () => (
        <Text>
          You are about to unmanage <b>{selectedCampaign?.name}</b>.
        </Text>
      ),
      renderText2: () => (
        <Text mt={4}>59A will immediately cease managing this campaign. Please ensure that you are ready to take control of this campaign.</Text>
      ),
      confirmButtonText: "Unmanage Campaign",
      confirmButtonLoading: setManagedLoading,
      onConfirm: confirmUnmanageCampaign,
      cancelRef: cancelConfirmUnmanageRef,
      onCancelOrClose: onUnmanageConfirmationClose,
    })
  }, [
    isUnmanageConfirmationOpen,
    onUnmanageConfirmationClose,
    selectedCampaign,
    setPlatformCampaignManaged,
    clientId,
    integrationId,
    setSelectedCampaign,
    setManagedError,
    setManagedLoading,
    cancelConfirmUnmanageRef,
  ])

  const tableColumns = useMemo(() => {
    const handleToggleManageCampaign = async (row: CampaignsTableRowData) => {
      const platformCampaign = tableData?.find((campaign) => campaign.platformCampaignId.id === row.campaignId)
      setSelectedCampaign(platformCampaign)

      if (row.managed) {
        return onUnmanageConfirmationOpen()
      } else {
        navigate(RoutePaths.campaignManagement.resolve(row.clientId.toString(), integrationId, row.platform, row.advertiserId, row.campaignId))
      }
    }

    const columns = [
      columnHelper.accessor("managed", {
        id: "managedForSort",
        cell: (info) => info.getValue(),
        header: () => <span>Managed Sort</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("campaignId", {
        id: "campaignId",
        cell: (info) => info.getValue(),
        header: () => <span>Campaign Id</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "assignees",
        cell: ({ row }) => (
          <Tooltip
            placement="top"
            shouldWrapChildren
            label={
              <VStack>
                {row.original.assignees?.map((assignee, index) => (
                  <Text key={index} fontSize="1.1rem">{`${assignee.name.first} ${assignee.name.last}`}</Text>
                ))}
              </VStack>
            }
          >
            <Flex align="center">
              <Flex justify="space-between" align="center" px={1} maxWidth="7.5rem" overflowX="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {row.original.assignees?.map((assignee, index) => (
                  <Avatar key={index} mr={2} size="sm" name={`${assignee.name.first} ${assignee.name.last}`} />
                ))}
              </Flex>
              {row.original.assignees?.length && row.original.assignees.length > 3 && <Box mt="-0.5rem">...</Box>}
            </Flex>
          </Tooltip>
        ),
        header: () => <span>Assignees</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "platform",
        cell: ({ row }) => (
          <div className="px-1">
            <DSPLogoMapper platform={row.original.platform} />
          </div>
        ),
        header: () => <span>Platform</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => {
          const isSetupInProgress = row.original.platformSetupStatus === "SetupInProgress"
          const isResetInProgress = row.original.platformSetupStatus === "ResetInProgress"
          const isEditDisabled = isSetupInProgress || isResetInProgress || setManagedLoading

          return (
            <HStack>
              {row.original.managed && canViewReports(permissions, platformCampaignResourceUri) && (
                <Button
                  onClick={() =>
                    navigate(
                      RoutePaths.campaignDashboard.resolve(
                        clientId.toString(),
                        integrationId,
                        row.original?.platform ?? "",
                        row.original?.advertiserId ?? "",
                        row.original?.campaignId ?? ""
                      ),
                      {
                        state: {
                          currency: row.original.currency,
                          name: row.original.name,
                          campaignType: row.original.campaignType,
                          kpis: row.original.kpis,
                        },
                      }
                    )
                  }
                  isInline={true}
                >
                  Reports
                </Button>
              )}
              {canUpdate(permissions, platformCampaignResourceUri) && (
                <Button
                  onClick={() =>
                    navigate(
                      RoutePaths.campaignManagement.resolve(
                        clientId.toString(),
                        integrationId,
                        row.original?.platform ?? "",
                        row.original?.advertiserId ?? "",
                        row.original?.campaignId ?? ""
                      )
                    )
                  }
                  isDisabled={isEditDisabled}
                  isInline={true}
                >
                  Edit
                </Button>
              )}
              {canUpdate(permissions, platformCampaignResourceUri) && (
                <Button onClick={() => onAssignUsers(row.original, allUsers)} isInline={true}>
                  Assignees
                </Button>
              )}
            </HStack>
          )
        },
      }),
    ] as ColumnDef<CampaignsTableRowData, unknown>[]

    if (integrationId && canUpdate(permissions, platformCampaignResourceUri)) {
      columns.splice(
        0,
        0,
        columnHelper.display({
          id: "managed",
          header: () => <span>Managed</span>,
          cell: ({ row }) => {
            const isSetupInProgress = row.original.platformSetupStatus === "SetupInProgress"
            const isResetInProgress = row.original.platformSetupStatus === "ResetInProgress"
            const isDisabled = isSetupInProgress || isResetInProgress || setManagedLoading
            return (
              <HStack>
                <Switch isDisabled={isDisabled} isChecked={row.original.managed} onChange={() => handleToggleManageCampaign(row.original)} />
                {(isSetupInProgress || isResetInProgress) && (
                  <Tooltip
                    label={`This campaign is being ${
                      isSetupInProgress ? "set up" : "reset"
                    } in the DSP. Please wait a few minutes and refresh the page to make further changes.`}
                    placement="right-start"
                  >
                    <InfoIcon color={"brand.400"} w={4} h={4} />
                  </Tooltip>
                )}
                {setManagedLoading && selectedCampaign?.platformCampaignId.id === row.original.campaignId && <Spinner size="sm" />}
              </HStack>
            )
          },
        })
      )
    }

    return columns
  }, [
    allUsers,
    onAssignUsers,
    integrationId,
    tableData,
    clientId,
    platformCampaignResourceUri,
    navigate,
    permissions,
    setManagedLoading,
    onUnmanageConfirmationOpen,
    selectedCampaign?.platformCampaignId.id,
  ])

  const tableRows = useMemo(() => {
    if (tableData?.length) {
      return toTableRow(tableData)
    } else {
      return [] as CampaignsTableRowData[]
    }
  }, [tableData])

  const error = tableError || setManagedError !== undefined

  if (tableRows.length === 0) {
    return (
      <Alert status="info" marginTop={12}>
        <AlertIcon />
        <Text color={colors.colors.brand["900"]}>
          No campaigns found. If you have just set the advertiser as managed, please wait a few minutes and refresh the page.
        </Text>
      </Alert>
    )
  }

  return (
    <>
      <PageTable
        pagination={{ pageIndex, pageSize }}
        onPaginationChange={setPagination}
        error={error}
        loading={tableLoading}
        defaultSort={[{ desc: true, id: "managedForSort" }]}
        columnVisibility={{ managedForSort: false }}
        hasGlobalFilter={true}
        heightOtherElementsRem={20.35}
        tableColumns={tableColumns}
        tableRows={tableRows}
      />
      <AlertDialog isOpen={isUnmanageConfirmationOpen} leastDestructiveRef={cancelConfirmUnmanageRef} onClose={onUnmanageConfirmationClose} size="2xl">
        {confirmationOpts && <ConfirmDialog {...confirmationOpts} />}
      </AlertDialog>
    </>
  )
}

export default CampaignsTable
